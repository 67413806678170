import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import './Header.css';

function Header({ onLogout }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleLogout = () => {
    Cookies.remove('isAuthenticated');
    Cookies.remove('token');
    onLogout();
    navigate('/');
  };

  return (
    <header className="header">
      <h1>Keep Green Plants - IOT</h1>
      <div className="profile-container">
        <button className="profile-button" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faUser} className="profile-icon" />
        </button>
        {menuOpen && (
          <div className="dropdown-menu">
            <button onClick={handleLogout} className="dropdown-item">
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
