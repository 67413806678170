import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './LoginPage.css';

const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
      value,
      expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};


function LoginPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch('https://iot-hmpr.onrender.com/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ password, username }),
        });

        if (response.ok) {
            const data = await response.json();

            Cookies.set('isAuthenticated', 'true', { expires: 1 / 24 });
            Cookies.set('token', data.Token, { expires: 1 / 24 });
            setWithExpiry('chipId', data['Chip Id'], 3600000);

            setIsAuthenticated(true);
            navigate('/table');
        } else if (response.status === 400 || response.status === 401) {
            setError('Invalid username or password');
            setTimeout(() => setError(''), 3000);
        } else {
            setError('Server error, please try again later');
            setTimeout(() => setError(''), 3000);
        }
    } catch (err) {
        setError('Network error, please try again later');
        setTimeout(() => setError(''), 3000);
    }
  };


  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
      {error && <div className="login-error">{error}</div>}
    </div>
  );
}

export default LoginPage;
