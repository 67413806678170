import React from 'react';

function Footer() {
  return (
    <footer style={{
      background: '#333', 
      color: 'white', 
      textAlign: 'center', 
      padding: '1rem', 
      marginTop: '2rem',
      width: '100%'
    }}>
      <p>&copy; 2025 - Epitech Project</p>
    </footer>
  );
}

export default Footer;