import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';    
import Cookies from 'js-cookie';
import './TablePage.css';

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }

  return item.value;
};

function TablePage() {
  const [currentTab, setCurrentTab] = useState('now');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [currentHumidity, setCurrentHumidity] = useState(null);
  const [currentTemp, setCurrentTemp] = useState(null);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState('Flower Plant');
  const chipID = getWithExpiry('chipId');
  const itemsPerPage = 10;

  const fetchCurrentData = async (chipID) => {
    const apiUrl = `https://iot-hmpr.onrender.com/api/mqtt/getDataNow/${chipID}`;
    const token = Cookies.get('token');

    if (!token) {
        throw new Error('Token utilisateur introuvable. Veuillez vous reconnecter.');
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.msg || `Erreur ${response.status}: Impossible de récupérer l'humidité actuelle`);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
  };

  const fetchPaginatedData = async (chipId, page) => {
    const apiUrl = `https://iot-hmpr.onrender.com/api/mqtt/getData/${chipId}/${page}`;
    const token = Cookies.get('token');

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.msg || `Erreur ${response.status}: Impossible de récupérer les données paginées`);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
  };


  const handleDownload = async () => {
    const apiUrl = `https://iot-hmpr.onrender.com/api/mqtt/export/${chipID}`;
    const token = Cookies.get('token');

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Error downloading the file.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `datas_${chipID}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error(err.message);
      setError('Failed to download the file.');
    }
  };

  const morePages = useCallback(async () => {
    try {
      await fetchPaginatedData(chipID, currentPage + 1);
      setHasMore(false);
    } catch {
      setHasMore(true);
    }
  }, [chipID, currentPage]);
  

  const checkHumidity = useCallback(
    (humidity) => {
      if (
        (selectedPlant === 'Flower Plant' && humidity < 30) ||
        (selectedPlant === 'Tropical Plant' && humidity < 75) ||
        (selectedPlant === 'Cactus' && humidity > 25)
      ) {
        alert(`Warning: Your ${selectedPlant.toLowerCase()} needs attention!`);
      }
    },
    [selectedPlant]
  );

  useEffect(() => {
    const fetchDataInterval = async () => {
      try {
        if (currentTab === 'now') {
          const currentData = await fetchCurrentData(chipID);
          setCurrentHumidity(currentData.humidity);
          setCurrentTemp(currentData.temperature);

          checkHumidity(currentData.humidity);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    const interval = setInterval(() => {
      fetchDataInterval();
    }, 10000);

    return () => clearInterval(interval);
  }, [chipID, currentTab, checkHumidity]);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        if (currentTab === 'now') {
          const currentData = await fetchCurrentData(chipID);
          setCurrentHumidity(currentData.humidity);
          setCurrentTemp(currentData.temperature);
        } else if (currentTab === 'historical') {
          const paginatedData = await fetchPaginatedData(chipID, currentPage);
          setDatas((prevData) => [...prevData, ...paginatedData]);
          morePages();
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    const authStatus = Cookies.get('isAuthenticated');
    if (authStatus !== 'true') {
      navigate('/');
    } else {
      fetchData();
    }
  }, [currentTab, chipID, currentPage, navigate, morePages]);
  


  const getDescription = (humidity) => {
    if (selectedPlant === 'Flower Plant') {
      if (humidity <= 30) return 'Severely low humidity. Your plant may not survive long.';
      if (humidity <= 50) return 'Low humidity. Your plant needs a bit more water.';
      if (humidity <= 75) return 'Moderate humidity. Your plant is fine no need to touch it.';
      if (humidity <= 100) return 'High humidity. Your plant have too much water, don’t hydrate it for a long time.';
      return 'Your plant is underwater! What are you doing?!';
    } else if (selectedPlant === 'Tropical Plant') {
      if (humidity < 75) return 'Too low humidity! Tropical plants need at least 75% to thrive.';
      if (humidity <= 100) return 'Ideal humidity for a tropical plant.';
      return 'Excessive humidity for tropical plants.';
    } else if (selectedPlant === 'Cactus') {
      if (humidity > 25) return 'Too much humidity! Cacti need a dry environment.';
      if (humidity >= 5) return 'Ideal humidity for a cactus.';
      return 'Very dry! Be sure to occasionally water your cactus.';
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = datas.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      {error === null ? (
        <div className="table-container">
          <div className='title-container'>
            <div className="title-section">
              <h2 className="title">Humidity Monitoring</h2>
              <button className="download-button" onClick={handleDownload}>
                Download Data
              </button>
            </div>
            <select
              className="plant-selector"
              value={selectedPlant}
              onChange={(e) => setSelectedPlant(e.target.value)}
            >
              <option value="Flower Plant">Flower Plant</option>
              <option value="Tropical Plant">Tropical Plant</option>
              <option value="Cactus">Cactus</option>
            </select>

            <div className="tabs">
              <button
                className={`tab-button ${currentTab === 'now' ? 'active' : ''}`}
                onClick={() => setCurrentTab('now')}
              >
                Now
              </button>
              <button
                className={`tab-button ${currentTab === 'historical' ? 'active' : ''}`}
                onClick={() => setCurrentTab('historical')}
              >
                Historical
              </button>
            </div>
          </div>

          <div className="data-container">
            {currentTab === 'now' && (
              <div className="now-tab">
                {loading === false ? (
                  <div className="gauge-wrapper">
                    <div className="gauge">
                      <div
                        className="gauge-level"
                        style={{
                          height: `${(currentHumidity / 100) * 100}%`,
                        }}
                      ></div>

                      <div className="graduations">
                        {Array.from({ length: 10 }, (_, i) => (
                          <div
                            key={i}
                            className="graduation"
                            style={{ bottom: `${i * 10}%` }}
                          >
                            <span className="graduation-number">{i * 10}%</span>
                          </div>
                        ))}
                      </div>
                      <div className="graduations">
                          <div
                            className="graduation100"
                            style={{ bottom: "100%"}}
                          >
                            <span className="graduation-number100">100%</span>
                          </div>
                      </div>
                      <div
                        className="indicator"
                        style={{
                          bottom: `${(currentHumidity / 100) * 100}%`,
                        }}
                      >
                        <span className="now-label">Now</span>
                      </div>
                    </div>

                    <div className="gauge-details">
                      <h3>Current Humidity</h3>
                      <p>{getDescription(currentHumidity)}</p>

                      <div
                        className="gauge-humidity"
                        style={{ color: `hsl(${(currentHumidity / 100) * 120}, 100%, 40%)` }}
                      >
                        {currentHumidity}% humidity
                      </div>
                      <div
                        className="gauge-humidity"
                        style={{ color: `hsl(${(currentHumidity / 100) * 120}, 100%, 40%)` }}
                      >
                        {currentTemp} degrees
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Loading current humidity...</p>
                )}
              </div>
            )}
            {currentTab === 'historical' && (
              <div className="historical-tab">
                {loading === false ? (
                  <div className="historical-tab">
                    <table className="historical-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Humidity</th>
                          <th>Temperature</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {new Date(data.timestamp).toLocaleString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </td>
                            <td>{data.humidity}</td>
                            <td>{data.temperature}</td>
                            <td>{getDescription(data.humidity)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span>Page {currentPage}</span>
                      <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={hasMore}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>Loading historical data...</p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='Error'>{error}</div>
      )}
    </div>
  );
}

export default TablePage;
