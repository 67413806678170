import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import TablePage from './pages/TablePage';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Cookies from 'js-cookie';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = Cookies.get('isAuthenticated');
    console.log(authStatus);
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated === null) return;

    if (isAuthenticated) {
      navigate('/table');
    } else {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove('isAuthenticated');
    Cookies.remove('token');
    navigate('/');
  };

  return (
    <div className="App">
      <Header onLogout={handleLogout} />
      <div className="App-main">
        <Routes>
          <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/table" element={isAuthenticated ? <TablePage /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
